import * as ApiHelpers from './api_helper';

const apiRoute = 'apps';

export const AppRepository = {
  GetApp: id => ApiHelpers.api_call_get(`${apiRoute}/${id}`),
  GetListApp: (page = 1, pageSize = 20, search = '') => {
    let path = ApiHelpers.GetApiUrl(apiRoute, {
      page,
      pageSize,
      search
    })
    return ApiHelpers.api_call_get(path);
  },
  PutApproveApp: data => ApiHelpers.api_call_put(apiRoute, data)
};
